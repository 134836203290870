body {
  margin: 0;
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* This will cause the rendered form to be vertically centered */
html {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color-scheme: light dark;
}
